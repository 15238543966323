import moment from "moment";
import { useStateContext } from "../contexts/ContextProvider";
const BookingSession = ({ date, id, index }) => {
  const { currentColor } = useStateContext();
  return (
    <div
      key={id}
      className="py-8 flex flex-col h-full shadow-sm md:max-h-96 md:flex-row w-full"
    >
      <div className="md:ml-3 rounded-md bg-white dark:bg-secondary-dark-bg text-black-shade dark:text-white-shade px-4 py-2 m-0 w-full">
        <div className="flex text-xs md:text-sm flex-col">
          <h2
            style={{ color: currentColor }}
            className="text-xl md:text-2xl font-medium title-font my-2"
          >
            Session {index} Date
          </h2>
          <div className="">{moment(date).format("ll")}</div>
        </div>
      </div>
    </div>
  );
};

export default BookingSession;
