import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../components/Loading";
import photo from "../data/logo.png";
import BookingSession from "../components/BookingSession";
import { useStateContext } from "../contexts/ContextProvider";
import { useFetchContext } from "../contexts/FetchContext";
import moment from "moment";

const BookingDetail = () => {
  const { fetchSingleBooking } = useFetchContext();
  const { currentMode } = useStateContext();
  const { id } = useParams();
  const { currentColor } = useStateContext();

  const [bookingDetails, setBookingDetails] = useState(null);

  useEffect(() => {
    (async () => {
      let { data, success } = await fetchSingleBooking(id);
      if (success) {
        console.log("🚀 ~ data:", data);
        setBookingDetails(data);
      }
    })();
  }, []);

  return (
    <div className="container px-5 py-5 flex justify-center dark:text-white-shade text-black-shade items-center flex-col mx-auto">
      {bookingDetails === null ? (
        <div className="min-h-[20rem] w-full items-center justify-center flex">
          <Loading
            color={currentMode === "Dark" ? "#20232A" : "#fafafa"}
            bgColor={currentMode === "Dark" ? "#fafafa" : "#20232A"}
          />
        </div>
      ) : (
        <>
          <div className="flex justify-between w-full mb-4">
            <h2 className="text-4xl items-center justify-center md:justify-start flex w-full font-medium title-font tracking-wide">
              Booking Details
            </h2>
          </div>
          <div className="md:ml-3 rounded-md bg-white mb-5 mt-2 dark:bg-secondary-dark-bg text-black-shade dark:text-white-shade px-4 py-1 m-0 w-full">
            <div className="pt-2 w-full pb-5">
              <div className="flex text-xs md:text-sm flex-col">
                <h2
                  style={{ color: currentColor }}
                  className="text-xl md:text-2xl pb-4 font-medium title-font my-2"
                >
                  {bookingDetails?.booking?.packageId?.name}
                </h2>
                <div className="space-y-3 text-base">
                  <div className="grid grid-cols-2 ">
                    <div>Day</div>
                    <div className="">
                      {moment(
                        bookingDetails?.sessions?.[0]?.sessionDate
                      ).format("dddd")}
                    </div>
                  </div>
                  <div className="grid grid-cols-2 ">
                    <div>Time</div>
                    <div className="">
                      {bookingDetails?.sessions?.[0]?.sessionTime}
                    </div>
                  </div>
                  <div className="grid grid-cols-2 ">
                    <div>Price</div>
                    <div className="">
                      {bookingDetails?.booking?.packageId?.price} $
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-between w-full mb-4">
            <h2 className="text-4xl items-center justify-center md:justify-start flex w-full font-medium title-font tracking-wide">
              User Sessions
            </h2>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 w-full">
            {bookingDetails?.sessions?.length > 0 ? (
              bookingDetails.sessions.map((session, i) => (
                <BookingSession
                  key={i}
                  id={session._id}
                  packageName={bookingDetails?.booking?.packageId?.name}
                  date={session?.sessionDate}
                  time={session?.sessionTime}
                  index={i + 1}
                />
              ))
            ) : (
              <div className="flex w-full col-span-3 items-center text-black-shade dark:text-white-shade justify-center">
                <h2 className=" mt-4 mb-8 text-2xl">No Sessions Found</h2>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default BookingDetail;
