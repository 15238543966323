import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { FiSettings } from "react-icons/fi";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { Navbar, Sidebar, ThemeSettings } from "./components";
import {
  Ecommerce,
  Orders,
  Customers,
  Products,
  ProductEdit,
  ProductDetail,
  CustomerDetail,
  AppointmentDetail,
  AdminProfile,
  Packages,
  CreatePackage,
  EditPackage,
  CreateClass,
  Categories,
  CreateCategory,
  EditClass,
  Bookings,
  BookingDetail,
} from "./pages";
import "./App.css";
import { useStateContext } from "./contexts/ContextProvider";
import { FaQuestion } from "react-icons/fa6";
const Home = () => {
  const {
    activeMenu,
    themeSettings,
    setThemeSettings,
    currentMode,
    currentColor,
    guide,
    handleClick,
  } = useStateContext();

  const steps = [
    {
      title: "Step 1: Create a Category",
      description:
        "The first step is to create a category. Categories help in organizing packages and classes effectively.",
      instructions: [
        "Navigate to the Categories section.",
        "Click on the Add New Category button.",
        "Enter a name for your category.",
        "Create your category to make it available for the next steps.",
      ],
    },
    {
      title: "Step 2: Create a Package",
      description: "Create packages by selecting an category.",
      instructions: [
        "Go to the Packages section.",
        "Click on Add Package.",
        "Select a category from the dropdown list.",
        "Provide package details and save.",
      ],
    },
    {
      title: "Step 3: Create a Class",
      description:
        "Link classes to packages and avoid scheduling multiple classes on the same day.",
      instructions: [
        "Head over to the Classes section.",
        "Click on Add Class.",
        "Select the appropriate package from the dropdown.",
        "Enter the class details and ensure no classes are duplicated on the same day.",
        "Save your class to complete the setup.",
      ],
    },
  ];

  return (
    <div className="flex relative dark:bg-main-dark-bg">
      <div className="fixed right-4 bottom-4" style={{ zIndex: "1000" }}>
        <TooltipComponent content="Settings" position="TopCenter">
          <button
            type="button"
            className="text-3xl p-3 hover:drop-shadow-xl hover:bg-light-gray text-white"
            style={{ backgroundColor: currentColor, borderRadius: "50%" }}
            onClick={() => setThemeSettings(true)}
          >
            <FiSettings />
          </button>
        </TooltipComponent>
      </div>
      <div
        className="fixed flex items-end left-4 bottom-4"
        style={{ zIndex: "10000" }}
      >
        <TooltipComponent content="Guide" position="TopCenter">
          <button
            type="button"
            className="text-3xl p-3 hover:drop-shadow-xl hover:bg-light-gray text-white"
            style={{ backgroundColor: currentColor, borderRadius: "50%" }}
            onClick={() => handleClick("guide")}
          >
            <FaQuestion />
          </button>
        </TooltipComponent>
        {guide && (
          <div
            style={{ backgroundColor: currentColor }}
            className="p-2 h-64 mb-8 ml-4 border border-white text-white rounded-2xl shadow-xl pl-3 w-72"
          >
            <div className="h-full overflow-y-auto ">
              <div className="flex items-center justify-between">
                <h2 className="text-2xl font-bold mb-2">Guide Steps</h2>{" "}
                <span
                  onClick={() => handleClick("guide")}
                  className="h-8 w-8 cursor-pointer flex items-center justify-center text-white rounded-full "
                >
                  X
                </span>
              </div>

              {steps.map((step, index) => (
                <div key={index} className="mb-4 text-white-shade">
                  <h3 className="text-md font-semibold">{step.title}</h3>
                  <p className="text-sm text-white-shade">{step.description}</p>
                  <ul className="list-disc list-inside mt-2">
                    {step.instructions.map((instruction, idx) => (
                      <li className="space-y-3" key={idx}>
                        {instruction}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      {activeMenu ? (
        <div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white">
          <Sidebar />
        </div>
      ) : (
        <div className="w-0 dark:bg-secondary-dark-bg">
          <Sidebar />
        </div>
      )}
      <div
        className={`dark:bg-main-dark-bg transition-all bg-main-bg min-h-screen w-full ${
          activeMenu ? "md:ml-72 sm:pr-72 md:pr-0" : "flex-2"
        }`}
      >
        <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full">
          <Navbar />
        </div>
        <div>
          {themeSettings && <ThemeSettings />}
          <Routes>
            <Route path="/*" element={<Navigate to={"/dashboard"} />} />
            <Route path="/dashboard" element={<Ecommerce />} />
            <Route path="/customers" element={<Customers />} />
            <Route path="/customer/:id" element={<CustomerDetail />} />
            <Route path="/bookings" element={<Bookings />} />
            <Route path="/booking/:id" element={<BookingDetail />} />
            <Route path="/categories" element={<Categories />} />
            <Route path="/create-category" element={<CreateCategory />} />
            <Route path="/packages" element={<Packages />} />
            <Route path="/create-package" element={<CreatePackage />} />
            <Route path="/edit-package/:id" element={<EditPackage />} />
            <Route path="/classes" element={<Products />} />
            <Route path="/create-class" element={<CreateClass />} />
            <Route path="/edit-class/:id" element={<EditClass />} />
            {/* <Route path="/profile" element={<AdminProfile />} />
            {/* <Route path="/edit-product/:id" element={<ProductEdit />} />
            <Route path="/product/:id" element={<ProductDetail />} /> */}
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default Home;
